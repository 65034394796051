<template>
  <div class="download">
    <!-- 顶部导航 -->
    <nav-header btnId="download" :userInfo="userInfo"></nav-header>
    <!-- 分类 -->
    <div class="classify">
      <span class="classify-title">资料分类：</span>
      <ul class="classify-ul">
        <li
          class="classify-li"
          :class="{ 'classify-li-select': item.select }"
          v-for="(item, index) in classify"
          :key="index"
          @click="clickClassifyLi(item.id)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
    <!-- 课程 -->
    <div v-if="classify[0].select || classify[1].select" class="title-desc">
      英语
    </div>
    <div v-if="classify[0].select || classify[1].select" class="container">
      <div
        class="course-item"
        v-for="(item, index) in course.english.value"
        :key="index"
        @click="toCourseDetail(item.id, item.name)"
      >
        <img class="poster" :src="item.extJson.icon" alt="" />
        <div class="name">{{ item.name }}</div>
      </div>
    </div>
    <div v-if="classify[0].select || classify[2].select" class="title-desc">
      数学
    </div>
    <div v-if="classify[0].select || classify[2].select" class="container">
      <div
        class="course-item"
        v-for="(item, index) in course.math.value"
        :key="index"
        @click="toCourseDetail(item.id, item.name)"
      >
        <img class="poster" :src="item.extJson.icon" alt="" />
        <div class="name">{{ item.name }}</div>
      </div>
    </div>
    <div v-if="classify[0].select || classify[3].select" class="title-desc">
      逻辑
    </div>
    <div v-if="classify[0].select || classify[3].select" class="container">
      <div
        class="course-item"
        v-for="(item, index) in course.logic.value"
        :key="index"
        @click="toCourseDetail(item.id, item.name)"
      >
        <img class="poster" :src="item.extJson.icon" alt="" />
        <div class="name">{{ item.name }}</div>
      </div>
    </div>
    <div v-if="classify[0].select || classify[4].select" class="title-desc">
      写作
    </div>
    <div v-if="classify[0].select || classify[4].select" class="container">
      <div
        class="course-item"
        v-for="(item, index) in course.writing.value"
        :key="index"
        @click="toCourseDetail(item.id, item.name)"
      >
        <img class="poster" :src="item.extJson.icon" alt="" />
        <div class="name">{{ item.name }}</div>
      </div>
    </div>
    <div v-if="classify[0].select || classify[5].select" class="title-desc">
      提前面试
    </div>
    <div v-if="classify[0].select || classify[5].select" class="container">
      <div
        class="course-item"
        v-for="(item, index) in course.advance.value"
        :key="index"
        @click="toCourseDetail(item.id, item.name)"
      >
        <img class="poster" :src="item.extJson.icon" alt="" />
        <div class="name">{{ item.name }}</div>
      </div>
    </div>
    <div v-if="classify[0].select || classify[6].select" class="title-desc">
      调剂/复试
    </div>
    <div v-if="classify[0].select || classify[6].select" class="container">
      <div
        class="course-item"
        v-for="(item, index) in course.re.value"
        :key="index"
        @click="toCourseDetail(item.id, item.name)"
      >
        <img class="poster" :src="item.extJson.icon" alt="" />
        <div class="name">{{ item.name }}</div>
      </div>
    </div>
    <div v-if="classify[0].select || classify[7].select" class="title-desc">
      其他
    </div>
    <div v-if="classify[0].select || classify[7].select" class="container">
      <div
        class="course-item"
        v-for="(item, index) in course.other.value"
        :key="index"
        @click="toCourseDetail(item.id, item.name)"
      >
        <img class="poster" :src="item.extJson.icon" alt="" />
        <div class="name">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import NavHeader from "../components/NavHeader.vue";
export default {
  name: "download",
  components: {
    NavHeader,
  },
  data() {
    return {
      classify: [
        { id: "all", name: "全部", select: true },
        { id: "english", name: "英语", select: false },
        { id: "math", name: "数学", select: false },
        { id: "logic", name: "逻辑", select: false },
        { id: "writing", name: "写作", select: false },
        { id: "advance", name: "提前面试", select: false },
        { id: "re", name: "调剂/复试", select: false },
        { id: "other", name: "其他", select: false },
      ],
      course: {
        english: { name: "英语", value: [] },
        math: { name: "数学", value: [] },
        logic: { name: "逻辑", value: [] },
        writing: { name: "写作", value: [] },
        advance: { name: "提前面试", value: [] },
        re: { name: "调剂/复试", value: [] },
        other: { name: "其他", value: [] },
      },
      userInfo: null,
    };
  },
  created() {
    this.getUserInfo();
    this.getDownload();
  },
  methods: {
    // 获取用户信息
    getUserInfo() {
      this.axios
        .get("/loginInfo")
        .then((res) => {
          this.userInfo = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取下载资料
    getDownload() {
      this.axios
        .get("/api/common/classification/anon/list", {
          params: {
            types: [
              "ENGLISH",
              "MATH",
              "LOGIC",
              "WRITING",
              "ADVANCE",
              "RE",
              "OTHER",
            ].toString(),
          },
        })
        .then((res) => {
          console.log(res);
          for (var item in res.data) {
            for (var i = 0; i < res.data[item].length; i++) {
              res.data[item][i].extJson = JSON.parse(res.data[item][i].extJson);
            }
          }
          this.course.english.value = res.data.ENGLISH;
          this.course.math.value = res.data.MATH;
          this.course.logic.value = res.data.LOGIC;
          this.course.writing.value = res.data.WRITING;
          this.course.advance.value = res.data.ADVANCE;
          this.course.re.value = res.data.RE;
          this.course.other.value = res.data.OTHER;
        });
    },
    // 去资料详情
    toCourseDetail(id, name) {
      console.log("id:", id);
      if (document.body.clientWidth <= 900) {
        this.$message({
          message: "请在电脑端下载",
          type: "warning",
        });
      } else {
        if (!this.userInfo) {
          this.$message({
            message: "请登录",
            type: "warning",
          });
          return;
        }
        if (!this.userInfo.isMember) {
          this.$message({
            message: "仅会员可查看",
            type: "warning",
          });
          return;
        }
        this.$router.push(
          "/courseDetail?id=" + id + "&name=" + name + "&type=download"
        );
      }
    },
    // 点击分类
    clickClassifyLi(id) {
      console.log(id);
      for (let i = 0; i < this.classify.length; i++) {
        if (id === this.classify[i].id) {
          this.classify[i].select = true;
        } else {
          this.classify[i].select = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.download {
  display: flex;
  flex-direction: column;
  align-items: center;
  .classify {
    width: 1140px;
    height: 80px;
    display: flex;
    align-items: center;
    .classify-title {
      width: 82px;
      display: inline-block;
      margin-right: 8px;
      font-weight: bold;
      font-family: "Helvetica Neue", Helvetica, "PingFang SC",
        "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
    }
    .classify-ul {
      display: flex;
      list-style: none;
      .classify-li {
        display: list-item;
        min-width: 70px;
        text-align: center;
        margin-right: 10px;
        border: 1px solid transparent;
        height: 30px;
        line-height: 28px;
        border-radius: 17px;
        box-sizing: border-box;
        font-size: 14px;
        cursor: pointer;
        padding: 0 16px;
        &:hover {
          color: #fff;
          background: #1cb877;
          box-shadow: 0 2px 10px 0 rgba(11, 92, 58, 0.2);
        }
      }
      .classify-li-select {
        color: #fff;
        background: #1cb877;
        box-shadow: 0 2px 10px 0 rgba(11, 92, 58, 0.2);
      }
    }
  }
  .title-desc {
    border-left: 5px solid #1cb877;
    height: 21px;
    width: 1140px;
    padding-left: 10px;
    font-size: 24px;
    color: #333333;
    line-height: 21px;
    margin-bottom: 20px;
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
      "Microsoft YaHei", 微软雅黑, Arial, sans-serif;
    box-sizing: border-box;
  }
  .container {
    width: 1180px;
    display: flex;
    flex-wrap: wrap;
    .course-item {
      width: 270px;
      margin: 0 0 20px 23px;
      cursor: pointer;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      display: flex;
      align-items: center;
      .poster {
        width: 20px;
        height: 20px;
        display: block;
        margin: 0 10px;
      }
      .name {
        line-height: 40px;
        font-size: 16px;
        color: #07111b;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        transition: all 0.3s;
        height: 40px;
        font-family: "Helvetica Neue", Helvetica, "PingFang SC",
          "Hiragino Sans GB", "Microsoft YaHei", 微软雅黑, Arial, sans-serif;
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .download {
    .classify {
      width: 100%;
      height: auto;
      padding: 10px 0;
      .classify-title {
        display: none;
      }
      .classify-ul {
        flex-wrap: wrap;
        padding: 0 5px;
        .classify-li {
          width: 20%;
          padding: 0;
          margin: 0 ;
        }
      }
    }
    .title-desc {
      display: none;
    }
    .container {
      width: 100%;
      justify-content: center;
      .course-item {
        width: 90%;
        margin: 0 0 20px 0;
      }
    }
  }
}
</style>